@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

body {
  font-family: "Open Sans", sans-serif !important;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
}
a {
  text-decoration: none;
  color: #003e9b !important;
}
button {
  font-weight: 500 !important;
  opacity: 1 !important;
}
h1,
h2,
h3,
h4 {
  font-weight: 500 !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-0{
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.pb-30 {
  padding-bottom: 30px;
  padding-bottom: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.btn-modal {
  margin-top: 25px !important;
  float: right !important;
}
.text-right {
  text-align: right;
}
.header-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  background-color: #10313a;
}
.btn-theme {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  font-family: "Open Sans", sans-serif;
}
.ag-theme-alpine .ag-header-row {
  font-weight: 500 !important;
}
.MuiButtonBase-root-MuiTab-root {
  opacity: 1 !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500 !important;
}

.menu-item {
  padding: 0 6px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
a.menu-item {
  color: rgb(255 255 255 / 70%) !important;
}
.menu-item svg {
  fill: rgba(255, 255, 255, 0.7);
}
.menu-item.active {
  color: #eeff38 !important;
}
.menu-item.active svg {
  fill: #eeff38 !important;
}
.grid-tabs-container {
  display: flex;
  height: 44px;
  /* background-color: rgb(242, 242, 242); */
  position: relative;
  padding-left: 30px;
  border-bottom: 6px solid rgb(0, 62, 155);
}
.tab-header.active {
  color: #003e9b !important;
  border-bottom: 2px solid #003e9b !important;
}
.title-nav {
  min-height: 29px;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 10px;
}
.tab-header {
  width: auto !important;
  padding: 6px 16px 6px !important;
  min-width: 0 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-bottom-width: 0 !important;
  color: #1976d2 !important;
  font-size: 16px !important;
  text-decoration: none !important;
}
.tab-custom {
  width: 84px !important;
  border: solid 1px #d5d5d5 !important;
  padding: 6px 12px 6px !important;
  min-width: 120px !important;
  min-height: 33px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-bottom-width: 0 !important;
  color: #666363 !important;
  font-size: 16px !important;
  text-decoration: none;
}
.nav-bar {
  margin-top: 17px;
}
.nav-custom {
  border: solid 1px #d5d5d5 !important;
  padding: 6px 12px 6px !important;
  min-width: 120px !important;
  min-height: 33px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-bottom-width: 0 !important;
  color: #666363 !important;
  font-size: 16px !important;
  text-decoration: none;
}
.nav-custom.active {
  background-color: #003e9b;
  color: #fff !important;
}
.Mui-selected.tab-custom {
  color: #fff !important;
  border-color: #003e9b !important;
  background-color: #003e9b !important;
}
.MuiTabs-indicator {
  bottom: 10px !important;
  background-color: #003e9b !important;
}
.btn-cell-dropdown {
  background-color: #d4d4d4 !important;
  border-radius: 0 !important;
  margin-bottom: 4px !important;
  color: #000 !important;
  width: 100% !important;
}
.ag-theme-alpine {
  --ag-font-size: 14px !important;
  --ag-font-family: "Open Sans" !important;
  --ag-font-weight: 500 !important;
  /* --ag-border-color: #b42d2d !important; */
}
.MuiListSubheader-root {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.tab-spacing {
  padding: 0 !important;
}
.action-buttons {
  float: right;
}
.action-buttons button {
  padding: 12px 10px;
  margin-bottom: 10px;
}
.setting-nav-menu ul {
  list-style: none;
  padding-left: 0;
}
.setting-nav-menu li.nav-item a {
  text-decoration: none;
  font-size: 16px;
  color: #222628 !important;
  padding: 13px 12px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}
.setting-nav-menu li {
  padding: 10px 12px;
}
.setting-nav-menu li:hover {
  background-color: #d5d5d5;
}
.setting-nav-menu li.nav-item span {
  float: right;
  margin-right: 15px;
}
.setting-nav-menu li.nav-item span svg {
  width: 16px !important;
  color: #767676;
}
.setting-nav-menu li.nav-item.active {
  background-color: #d5d5d5;
}
.ag-cell-wrapper svg {
  width: 16px;
  height: 16px;
  /* color: #949a9f; */
  vertical-align: middle;
}
.ag-header-cell,
.ag-header-group-cell {
  border-right: 1px solid #d5d5d5 !important;
}
.ag-ltr .ag-cell {
  border-right-color: #d5d5d5 !important;
}
.ag-ltr .ag-cell:last-child {
  border-right-width: 0px solid !important;
}
.btn-bottom svg {
  vertical-align: middle;
  margin-right: 5px;
}

.btn-bottom button {
  font-weight: 600 !important;
}
.MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.card-header {
  padding: 18px 25px 0px;
}
.card-header p {
  font-size: 14px;
  margin-bottom: 0 !important;
  margin-top: 0;
}
.card-header h4 {
  font-size: 18px;
  font-weight: 700 !important;
  color: #000;
  line-height: 24px;
  margin-top: 2px;
  margin-bottom: 0px;
  border-bottom: 4px solid rgb(0 62 155 / 50%);
  padding-bottom: 15px;
}
.card-body {
  padding: 10px 25px 10px;
}
.form-custom {
  padding: 10px 15px !important;
}
.form-custom .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
}
.form-custom .MuiInputBase-root-MuiInput-root:before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}
.ctxUnderline {
  cursor: help;
  border-bottom: 1px dotted #aaa;
  font-size: 14px;
}
table,
th,
td {
  border: 1px solid rgb(224 224 224) !important;
}
th {
  font-weight: 600 !important;
}
/* thead {
  background: rgb(183 173 173 / 12%);
} */
.ql-editor {
  min-height: 50px;
  font-size: 16px !important;
}
a.header-nav {
  color: #222628 !important;
}
.header-nav li {
  padding: 8px 20px 8px 12px;
  font-size: 14px;
}
.header-nav .icon svg {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  min-width: 30px;
  color: #979393;
}
.header-sticky-button .MuiIconButton-root {
  background: #e0ff38 !important;
  color: #10313a;
  top: 33px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  min-height: 36px;
}
.calculation-summary-top {
  display: flex;
  justify-content: space-between;
}
.calculation-summary {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
}
.calculation-summary p {
  margin: 10px 0;
}
.calculation-divider {
  border-top: 4px solid #ccc;
  margin-top: 30px;
  margin-bottom: 15px;
}
th,
td {
  padding: 8px !important;
}
.login-bg {
  position: relative;
}

.login-bg {
  background-image: url("../images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.login-bg:before {
  content: "";
  position: absolute;
  background-color: #181d1f91;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.header-right {
  position: absolute;
  right: 20px;
}
.header-right-text p {
  color: #e0ff38;
  font-size: 14px;
  line-height: 20px;
}

.header-right-text button {
  color: #fff;
  padding: 0;
  min-width: 35px;
}
.text-white {
  color: #fff;
}
.header-top {
  font-size: 14px !important;
}
.header-top-icon svg {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 11px;
}
.error-message {
  color: red;
}
input.default-input {
  border: 0;
  border-bottom: 1px solid #949494;
  height: 25px;
  background: transparent;
  font-size: 16px;
}
input.default-input:focus-visible {
  outline: 0 !important;
  border-bottom: 2px solid #06c;
}

.MuiSelect-select {
  background: #e5e5e5;
  border: 0 !important;
  border-radius: 0 !important;
}
.stepper {
  display: flex;
  margin: 10px auto;
  padding: 0;
  width: 60rem;
  list-style: none;
  position: relative;
}
.stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 20px);
  background: #e7e7e7;
}
.stepper__item {
  flex: 100%;
  padding: 20px 20px 20px 40px;
  background: repeating-linear-gradient(
    -65deg,
    #d5d5d5,
    #d5d5d5 20px,
    #d5d5d5 20px,
    #d5d5d5 40px
  );
  margin: 0 0 0 -19px;
  -webkit-clip-path: polygon(
    20px 50%,
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
}
.stepper__item.current {
  background: #afcf00;
  color: #fff;
  font-weight: bold;
}
.stepper__item.complete {
  background: repeating-linear-gradient(
    -65deg,
    #ccc,
    #ccc 20px,
    #ccc 20px,
    #ccc 40px
  );
}
/* .stepper__item:first-child {
    padding: 20px;
    -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}
.stepper__item:last-child {
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
} */
.stepper__item.complete span {
  display: block;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between !important;
}
h4.title {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 600 !important;
  font-size: 16px;
}
.search-bar {
  display: flex;
  justify-content: end;
}
.search-input {
  min-width: 250px;
  max-height: 40px;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 10px;
  border: 1px solid #b3b3b3;
  padding: 0 10px;
}

.search-button {
  margin-left: 15px !important;
  padding: 0 30px !important;
  max-height: 40px;
  margin-right: 10px !important;
}
.search-input:focus-visible {
  outline: 0;
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: hidden !important;
    -webkit-print-color-adjust: exact;
    zoom: 75%;
    background: #fff;
  }
  .break-page {
     page-break-before: always;
  }
 
}

@page {
  size: auto;
  margin: 6mm;
}
.printsection {
  margin: 0 auto;
  background-color: #ffffff;
}
.action-btn svg {
  font-size: 16px;
  margin-right: 5px;
}
.action-btn span {
  font-size: 14px !important;
}
.text-center {
  text-align: center !important;
}
.order-view {
  margin: 30px auto;
  padding: 20px 20px;
}
.order-view table {
  width: 100%;
  border: 1px solid rgb(0 0 0 / 40%) !important;
}
.order-view table,
th,
td {
  border-collapse: collapse;
  text-align: left;
}
.order-view h3,
.order-view h2 {
  color: #000;
  font-weight: 700 !important;
  color: #222;
  font-weight: 600 !important;
}
.order-view.MuiCard-root {
  box-shadow: none;
  border-radius: 0;
}
.justify-contennt-between {
  justify-content: space-between;
}
.MuiTableContainer-root {
  box-shadow: none !important;
}
.MuiDialog-paper {
  overflow: hidden;
}
.signature-area {
  height: 100px;
}
.container-style{
  width: 100%;
  height: 100%;
}
.grid-style{
  width: 100%;
  height: 100%;
}
.card-widget h3 {
  color: #605e5e;
}
.widget-icon svg {
  font-size: 35px;
  background: rgb(16 49 58 / 80%);
  padding: 10px;
  border-radius: 50%;
  color: rgb(224 255 56);
}
/* .widget-icon svg {
  font-size: 35px;
  background: rgb(16 49 58 / 10%);
  padding: 10px;
  border-radius: 50%;
  color: #10313a;
} */
textarea {
  border: 0;
  border-bottom: 1px solid #949494;
  font-size: 15px;
  font-family: inherit;
}

textarea:focus-visible {
  outline: 0;
  border-bottom: 2px solid #2196f3;
}
.quotation-print td{
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.quotation-print td li{
  text-transform: capitalize;
}
.remove-image{
  position: absolute;
  right: -12px;
  top: -9px;
}
.remove-image svg {
  color: #fff;
  background-color: red;
  border-radius: 20px;
}
.quotation-content {
  display: flex;
  justify-content: space-between;
  /* font-weight: 600; */
  border-top: 1px solid #000;
}
.quotation-content:last-child {
  border-bottom: 1px solid #000;
}
.quotation-bg-color {
background-color: #ACACAC;
}
.quotation-grey-text{
    font-family: Roboto, sans-serif;
    font-weight: 400 !important;
    font-size: 14px;
}
h2.quotation-title {
  margin: 0;
  font-weight: 400 !important;
  font-family: 'Roboto', sans-serif;
}
.quotation-content .sub-title {
  font-weight: 500;
  margin: 2px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
.quotation-detail td, .quotation-detail th {
  vertical-align: top;
}
.quotation-detail table {
  width: 100%;
  border-collapse: collapse;
}
.quotation-print ol {
  padding-inline-start: 14px;
}
.quotation-detail table, .quotation-detail th, .quotation-detail td {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 5px 0 !important;
  /* border-top: 1px solid #000 !important; */
}
.quotation-detail th{
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  font-size: 14px;
}
.quotation-detail td{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.border-1{
  border-top: 1px solid #000 !important;
}
.border-transparent{
  border: 1px solid transparent !important;
}
.w-100{
  width: 100%;
}
.w-48 {
  width: 48%;
}
.w-25 {
  width: 25%;
}

.w-12 {
  width: 12.5%;
}

.w-2 {
  width: 2%;
}

.w-24 {
  width: 24%;
}

.w-4 {
  width: 4%;
}
.quotation-terms table, .quotation-terms th, .quotation-terms td{
  border-collapse: collapse;
  border: none !important;
}

.page-count {
  box-sizing: border-box;
  min-height: 90vh;
  /* height: 100%; */
  margin: 0;
  border-bottom: solid 1px #000;
  padding-top: 1px;
}
.page-count2 {
  box-sizing: border-box;
  min-height: 140vh;
  /* height: 100%; */
  margin: 0;
  border-bottom: solid 1px #000;
  padding-top: 1px;
}


/* Booklet section start */
.booklet-container{
  margin-right: 50px;
  /* border: 1px solid #000; */
  padding: 15px;
  position:relative;  
  /* border-left:2px solid #000;  */
  border-right:1px solid #000;
}

.booklet-container::after { 
   position:absolute;
   top:50px; 
   left:0; 
   background:#000; 
   width:105%; 
   height:1px; 
   content:"";
  }

.booklet-container::before { 
  position:absolute; 
  bottom:50px; 
  left:0; 
  background:#000; 
  width:105%; 
  height:1px; 
  content:"";
}
.vertical-logo {
transform: rotate(270deg);
position: absolute;
left: -200px;
top: 420px;
height: 300px;
}
.booklet-title {
float: right;
margin: 50px;
}

.booklet-title h1 {
font-size: 80px;
margin: 0;
font-family: 'Roboto', sans-serif;
font-weight: 400 !important;
text-align: right;
}

.booklet-title p {
margin: 0;
background: #000;
color: #fff;
width: fit-content;
text-align: right;
padding: 10px 20px;
font-size: 20px;
}
.booklet-customer-detail {
float: right;
margin-top: 640px;
}

.booklet-customer-detail p {
text-align: right;
letter-spacing: 4px;
font-family: 'Roboto', sans-serif;
}
.booklet-table table, .booklet-table table th, .booklet-table table td {
  border-collapse: collapse;
  border: 1px solid #c3c3c3 !important;
}
.booklet-table table th{
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  letter-spacing: 1px;
  text-align: center;
}
.booklet-table table td{
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400 !important;
}

.bg-grey {
  background: grey;
}
.bg-light-grey{
  background: #dfdede;
}
.bg-blue {
  background: #0e80db;
}
.text-error {
  color: #ef0404;
}
.border{
  border: 1px solid #000;
}
.design-specification {
  border: 2px dashed #000;
  position: relative;
  
}
.p-5{
  padding: 5px !important;
}
.pl-5{
  padding-left: 5px !important;
}
.drawing-content {
  border-top: 1px solid #000;
  padding-top: 6px;
}
.drawing-content h4 {
  font-weight: 600 !important;
}
.drawing-content p {
  margin-bottom: 5px !important;
  margin-top: 0;
}
.revision-content p {
  font-size: 14px;
}
.design-specification h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400 !important;
}
.design-sidebar-logo{
  padding: 15px 10px;
}
.specification-content{
  font-size: 13px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding-right: 10px;
}
.border-bottom-1{
  border-bottom: 1px solid #000000;
}
.design-specification p {
  margin: 0;
  font-size: 14px;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif;
  padding: 3px 0;
}
.design-specification h2, .design-specification span {
  text-decoration: underline;
}
.design-specification h2 {
  font-size: 17px;
}
.design-specification h4 {
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif;
  color: #000;
  padding: 5px 0;
  margin: 0;
}
.design-specification h5 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif;
  text-decoration: underline;
}
.revision-content span {
  display: block;
  text-decoration: none;
  font-weight: 600;
}

.design-specification li {
  font-size: 14px;
}

.design-specification ol {
  padding-inline-start: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
}
.drawing-image img {
  height: 780px;
  object-fit: contain;
}
.referrence-image{
    object-fit: contain;
    height: 85px;
    /* margin-bottom:20px; */
}
.float-right{
  float: right;
}
.p-0 {
  padding: 0 !important;
}
@media print{
  .print-booklet{
        size: A3 landscape; 
        zoom: 150%;
  }
}

.btn-upload {
    padding: 5px 33px;
    background: #1976d2;
    border: 1px solid #1976d2;
    color: #fff;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 34px;
    line-height: 34px;
}
.download-link{
  margin-top: 20px;
  margin-right: 10px;
}
.design-booklet textarea {
    border: 1px solid #949494;
    font-size: 15px;
    font-family: inherit;
    background: transparent;
    padding: 10px;
    width: 98%;
}

.border-0 {
  border: none !important;
}

.export-excel-table table, .export-excel-table td, .export-excel-table th{
  border: 1px solid #cacaca !important;
    border-collapse: collapse;
}
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border-width: 3px 2px 3px 2px;
  border-style: solid dotted solid dotted;
  border-color: #10313a #8d8d8d #b12526 #8d8d8d;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.loader:before , .loader:after{
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color:#b12526;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.loader:after {
  border-color: #10313a #0000 #0000 #0000 ;
  transform: translate(32px, 3px) rotate(-35deg);
}
 @keyframes rotate {
  100%{    transform: rotate(360deg)}
}